function displayPersonnel( target, request) {

	const ajax_url = '/ajax/results.php',
		loading = document.querySelector('#loading_spinner')

	const http = new XMLHttpRequest();

	target.classList.add('transition')
	loading.style = 'display: block'
	
	http.onreadystatechange = function() {
		if (this.readyState == 4) {
			target.innerHTML = http.responseText
			setTimeout(function() {
				loading.style = 'display:none';
				target.classList.remove('transition')
				target.style = 'min-height: 0px'
			}, 100)
			setTimeout(function() {
				pageScroll.update()
				let height = target.offsetHeight
				target.style = 'min-height: ' + height + 'px'
			}, 500)
		}
	}
	http.open('POST', ajax_url, true)
	http.setRequestHeader('Content-type', 'application/x-www-form-urlencoded')
	http.send(request)
}

function displayFilters( target, request) {

	const ajax_url = '/ajax/filters.php'

	const http = new XMLHttpRequest();
	
	http.onreadystatechange = function() {
		if (this.readyState == 4) {
			target.innerHTML = http.responseText
			selects();
			setTimeout(function() {
				jQuery('#club').on('change', filterChange)
				jQuery('#position').on('change', filterChange)
				jQuery('#qualification').on('change', filterChange)
				pageScroll.update()
			}, 500)
		}
	}
	http.open('POST', ajax_url, true)
	http.setRequestHeader('Content-type', 'application/x-www-form-urlencoded')
	http.send(request)

	
}

function updatePersonnel(paged = 1, club = '', position = '', qualification = '') {
	const target = document.querySelector('#people_results'),
		filterTarget = document.querySelector('#people_filter'),
		type = target.dataset.type
		per_page = target.dataset.perPage
	
	let request = 'type=' + type
		request += '&paged=' + paged
		request += '&per_page=' + per_page
	
	if(type == 'coach') {
		request += '&club=' + club
		request += '&position=' + position
		request += '&qualification=' + qualification
		displayFilters(filterTarget, request)
	}
	displayPersonnel(target, request)
}

function selects() {
	jQuery('.people_filter').select2({
		//maximumSelectionLength: 1,
		minimumResultsForSearch: 100,
		allowClear: true,
		width: '250px',
		placeholder: function() {
			jQuery(this).data('placeholder');
		},
	});
}

function filterChange() {
	let club = document.querySelector('#club').value
	let position = document.querySelector('#position').value
	let qualification = document.querySelector('#qualification').value
	updatePersonnel(1, club, position, qualification)
}

function pageChange(newPage) {
	const type = document.querySelector('#people_results').dataset.type
	if(type == 'coach') {
		let club = document.querySelector('#club').value
		let position = document.querySelector('#position').value
		let qualification = document.querySelector('#qualification').value
		updatePersonnel(newPage, club, position, qualification)
	} else {
		updatePersonnel(newPage)
	}
}

{
	const target = document.querySelector('#people_results')
	if(target) {
		updatePersonnel()
	}
}

