{
	fontCheck()
}

function fontCheck() {
	var font = new FontFaceObserver('Big Shoulders Display', {
		weight: 800,
	});

	font.load().then(function () {
		document.querySelector('.page-scroll-container').style = "opacity: 1"
	}).catch(function () {
		setTimeout(function() {
			document.querySelector('.page-scroll-container').style = "opacity: 1"
		}, 1000)
	});
}

function navToggle() {
	const navCont = document.querySelector('.nav_container')	
	navCont.classList.toggle('in')
	document.body.classList.toggle('noscroll')
}

const toggle = document.querySelector('.nav_toggle')
toggle.addEventListener("click", navToggle)

function resizeNav() {
	if(window.innerWidth > 992) {
		document.body.classList.remove('noscroll')
		document.querySelector('.nav_container').classList.remove('in')
	}
}
