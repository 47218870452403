function displayVenues( target, request) {

	const ajax_url = '/ajax/venues.php',
		loading = document.querySelector('#loading_spinner')

	const http = new XMLHttpRequest();

	target.classList.add('transition')
	loading.style = 'display: block'
	
	http.onreadystatechange = function() {
		if (this.readyState == 4) {
			target.innerHTML = http.responseText
			setTimeout(function() {
				loading.style = 'display:none';
				target.classList.remove('transition')
				target.style = 'min-height: 0px'
			}, 100)
			setTimeout(function() {
				pageScroll.update()
				let height = target.offsetHeight
				target.style = 'min-height: ' + height + 'px'
			}, 500)
		}
	}
	http.open('POST', ajax_url, true)
	http.setRequestHeader('Content-type', 'application/x-www-form-urlencoded')
	http.send(request)
}

function updateVenues(paged = 1) {
	const target = document.querySelector('#venue_results'),
		type = target.dataset.type
		per_page = target.dataset.perPage
	
	let request = 'type=' + type
		request += '&paged=' + paged
		request += '&per_page=' + per_page

	displayVenues(target, request)
}


function pageVenueChange(newPage) {	
	updateVenues(newPage)
}

{
	const target = document.querySelector('#venue_results')
	if(target) {
		updateVenues()
	}
}

