function displayCareers( target, request) {

	const ajax_url = '/ajax/careers.php',
		loading = document.querySelector('#career_loading_spinner')

	const http = new XMLHttpRequest();

	target.classList.add('transition')
	loading.style = 'display: block'
	
	http.onreadystatechange = function() {
		if (this.readyState == 4) {
			target.innerHTML = http.responseText
			setTimeout(function() {
				loading.style = 'display:none';
				target.classList.remove('transition')
				target.style = 'min-height: 0px'
			}, 100)
			setTimeout(function() {
				pageScroll.update()
				let height = target.offsetHeight
				target.style = 'min-height: ' + height + 'px'
			}, 500)
		}
	}
	http.open('POST', ajax_url, true)
	http.setRequestHeader('Content-type', 'application/x-www-form-urlencoded')
	http.send(request)
}


function updateCareers(paged = 1) {
	const target = document.querySelector('#careers_results')
	let request = 'paged=' + paged	
	displayCareers(target, request)
}


function pageChangeCareer(newPage) {
	const type = document.querySelector('#careers_results').dataset.type
	updateCareers(newPage)
}

{
	const careerTarget = document.querySelector('#careers_results')
	if(careerTarget) {
		updateCareers()
	}
}

