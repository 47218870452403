function displayTennisCentres( target, request) {

	const ajax_url = '/ajax/try-tennis.php',
		loading = document.querySelector('#loading_spinner_clubs')

	const http = new XMLHttpRequest();

	target.classList.add('transition')
	loading.classList.add('loading')

	const selectedClass = document.querySelector('.selected_class')
	selectedClass.classList.remove('show')
	const form = document.querySelector('.acf-form')
	form.classList.remove('show')
	
	http.onreadystatechange = function() {
		if (this.readyState == 4) {
			target.innerHTML = http.responseText
			setTimeout(function() {
				loading.classList.remove('loading')
				target.classList.remove('transition')
				target.style = 'min-height: 0px'
			}, 100)
			setTimeout(function() {
				pageScroll.update()
				let height = target.offsetHeight
				target.style = 'min-height: ' + height + 'px'
			}, 500)
		}
	}
	http.open('POST', ajax_url, true)
	http.setRequestHeader('Content-type', 'application/x-www-form-urlencoded')
	http.send(request)
	
}

function updateTennisCentres(paged = 1) {
	const target = document.querySelector('#postclubResults')
	
	const postcodeForm = document.querySelector('#centrePostcodeSearch')
	let postcodeLookup = postcodeForm.elements['postcode'].value
	const geocoder = new google.maps.Geocoder()

	geocoder.geocode(
		{
			address: postcodeLookup,
		},
		function(results, status) {
			
			if(status === 'OK') {
				let request = 'paged=' + paged
				request += '&postcode=' + postcodeLookup
				request += '&lat=' + results[0].geometry.location.lat()
				request += '&lng=' + results[0].geometry.location.lng()
				displayTennisCentres(target, request)
			}
		}
	)
}

function setupCentreSearch() {
	const target = document.querySelector('#postclubResults')
	if(target) {
	
		const postcodeForm = document.querySelector('#centrePostcodeSearch')
		let postcodeLookup = postcodeForm.elements['postcode']

		const options = {
			componentRestrictions: { country: ["uk"] },
			fields: ["geometry", "address_components"],
		};

		const autocomplete_locate = new google.maps.places.Autocomplete(postcodeLookup, options);

		google.maps.event.addListener(autocomplete_locate, 'place_changed', function () {
			updateTennisCentres()
		});

		postcodeForm.addEventListener('submit', (e) => {
			e.preventDefault()
			updateTennisCentres()
		})
	}
}

function displayTennisClasses( target, request ) {

	const ajax_url = '/ajax/try-tennis-classes.php',
		loading = document.querySelector('#loading_spinner_class')
		http1 = new XMLHttpRequest()

	target.classList.add('transition')
	loading.classList.add('loading')

	http1.onreadystatechange = function() {
		
		if(this.readyState == 4) {
			target.innerHTML = http1.responseText
			setTimeout(function() {
				loading.classList.remove('loading')
				target.classList.remove('transition')
				target.style = 'min-height: 0px'
			}, 100)
			setTimeout(function() {
				pageScroll.update()
				let height = target.offsetHeight
				target.style = 'min-height: ' + height + 'px'
			}, 500)
		}
	}
	http1.open('POST', ajax_url, true)
	http1.setRequestHeader('Content-type', 'application/x-www-form-urlencoded')
	http1.send(request)
}

function updateTennisClasses(id) {
	const target = document.querySelector('#classResults')
	let request = 'club=' + id
	displayTennisClasses(target, request)
}

function updateVisibleClubs(id) {
	const centres = document.querySelectorAll('.tennis_centre')
	centres.forEach( (el) => {
		let el_id = el.dataset.centreid
		if(el_id == id) {
			el.classList.remove('fade')
			el.classList.add('selected')
		} else {
			el.classList.remove('selected')
			el.classList.add('fade')
		}
	})
}

function showClassesBtn(id, title) {
	updateVisibleClubs(id)
	updateTennisClasses(id)

	const clubText = document.querySelector('.selected_class .club')
	clubText.innerHTML = title
	const formField = document.querySelector('[data-name="selected_club"] input')
	formField.value = title

	const formClass = document.querySelector('[data-name="selected_session_name"] input')
	const formDate = document.querySelector('[data-name="selected_session_date"] input')
	formClass.value = ''
	formDate.value = ''

	const selectedClass = document.querySelector('.selected_class')
	selectedClass.classList.remove('show')
	const form = document.querySelector('.acf-form')
	form.classList.remove('show')

}

function classWatch(selectCount, element, className) {
	const classSelects = document.querySelectorAll('.class select')
	classSelects.forEach( (el) => {
		if(el["name"] != 'classSession_' + selectCount) {
			el.selectedIndex = 0
		}
	})
	let selected = element.value
	const dateText = document.querySelector('.selected_class .date')
	dateText.innerHTML = selected
	const selectedClass = document.querySelector('.selected_class')
	selectedClass.classList.add('show')
	const form = document.querySelector('.acf-form')
	form.classList.add('show')
	
	const formClass = document.querySelector('[data-name="selected_session_name"] input')
	const formDate = document.querySelector('[data-name="selected_session_date"] input')
	formClass.value = className
	formDate.value = selected

	pageScroll.update()

}
