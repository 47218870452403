const pageScroll = new LocomotiveScroll({
	el: document.querySelector('[data-scroll-container]'),
	smooth: true,
})


function pageScrollCheck() {
	setTimeout(function() {
		pageScroll.update()
	}, 1000)
}

window.addEventListener("load", pageScrollCheck)

var resizeTimer;
window.addEventListener('resize', function() {
	clearTimeout(resizeTimer);
	resizeTimer = setTimeout(function() {
		pageScrollCheck()
		resizeNav()
	}, 500)
})