const bookaby_mid = 'bc5cdd7e-5069-4329-9073-6c1a4a58648f';

function displayTennisCentresSummer( target, request) {

	const ajax_url = '/ajax/summer-tennis.php',
		loading = document.querySelector('#loading_spinner_clubs')

	const http = new XMLHttpRequest();

	target.classList.add('transition')
	loading.classList.add('loading')

	const selectedClass = document.querySelector('.selected_class')
	selectedClass.classList.remove('show')
	const form = document.querySelector('.acf-form')
	form.classList.remove('show')
	
	http.onreadystatechange = function() {
		if (this.readyState == 4) {
			target.innerHTML = http.responseText
			setTimeout(function() {
				loading.classList.remove('loading')
				target.classList.remove('transition')
				target.style = 'min-height: 0px'
			}, 100)
			setTimeout(function() {
				pageScroll.update()
				let height = target.offsetHeight
				target.style = 'min-height: ' + height + 'px'
			}, 500)
		}
	}
	http.open('POST', ajax_url, true)
	http.setRequestHeader('Content-type', 'application/x-www-form-urlencoded')
	http.send(request)
}

function updateTennisCentresSummer(paged = 1) {
	const target = document.querySelector('#postclubResultsSummer')
	
	const postcodeForm = document.querySelector('#centrePostcodeSearchSummer')
	let postcodeLookup = postcodeForm.elements['postcode'].value
	const geocoder = new google.maps.Geocoder()

	geocoder.geocode(
		{
			address: postcodeLookup,
		},
		function(results, status) {
			
			if(status === 'OK') {
				let request = 'paged=' + paged
				request += '&postcode=' + postcodeLookup
				request += '&lat=' + results[0].geometry.location.lat()
				request += '&lng=' + results[0].geometry.location.lng()
				displayTennisCentresSummer(target, request)
			}
		}
	)
}

function setupCentreSearchSummer() {
	const target = document.querySelector('#postclubResultsSummer')
	if(target) {
	
		const postcodeForm = document.querySelector('#centrePostcodeSearchSummer')
		let postcodeLookup = postcodeForm.elements['postcode']

		const options = {
			componentRestrictions: { country: ["uk"] },
			fields: ["geometry", "address_components"],
		};

		const autocomplete_locate = new google.maps.places.Autocomplete(postcodeLookup, options);

		google.maps.event.addListener(autocomplete_locate, 'place_changed', function () {
			updateTennisCentresSummer()
		});

		postcodeForm.addEventListener('submit', (e) => {
			e.preventDefault()
			updateTennisCentresSummer()
		})
	}
}

function displayTennisClassesSummer( target, request ) {

	const ajax_url = '/ajax/summer-tennis-classes.php',
		loading = document.querySelector('#loading_spinner_class')
		http1 = new XMLHttpRequest()

	target.classList.add('transition')
	loading.classList.add('loading')

	http1.onreadystatechange = function() {
		
		if(this.readyState == 4) {
			target.innerHTML = http1.responseText
			setTimeout(function() {
				loading.classList.remove('loading')
				target.classList.remove('transition')
				target.style = 'min-height: 0px'
			}, 100)
			setTimeout(function() {
				pageScroll.update()
				let height = target.offsetHeight
				target.style = 'min-height: ' + height + 'px'
			}, 500)
		}
	}
	http1.open('POST', ajax_url, true)
	http1.setRequestHeader('Content-type', 'application/x-www-form-urlencoded')
	http1.send(request)
}

function updateTennisClassesSummer(id) {
	const target = document.querySelector('#classResults')
	let request = 'club=' + id
	displayTennisClassesSummer(target, request)
}

function showCampsBtn(id, title) {
	updateVisibleClubs(id)
	updateTennisClassesSummer(id)

	const clubText = document.querySelector('.selected_class .club')
	clubText.innerHTML = title	

	const selectedClass = document.querySelector('.selected_class')
	selectedClass.classList.remove('show')
	const form = document.querySelector('.acf-form')
	form.classList.remove('show')

}